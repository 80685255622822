<template>

  <div>
    <div class="row mb-2">
      <div class="col-sm-4">
        <router-link to="/rentals/add-car" class="btn btn-danger mb-2"
        ><i class="mdi mdi-plus-circle mr-1"></i> Add New Car
        </router-link
        >
      </div>

      <div class="col-sm-4">
        <div
            id="tickets-table_filters"
            class="dataTables_filter text-md-right"
        >
          <label class="d-inline-flex align-items-center">
            Filters:
            <select class="form-control form-control-sm ml-2" v-model="selectedStatus">
              <option value="all">All</option>
              <option value="in-use">In Use</option>
              <option value="available"> Available</option>
            </select>
          </label>
        </div>

      </div>
      <div class="col-sm-4">
        <div class="float-sm-right">
          <form class="form-inline">
            <div class="form-group mr-2">
              <label class="d-inline-flex align-items-center">
                Search:
              <input
                  type="search"
                  class="form-control border-light ml-2"
                  id="productssearch-input"
                  v-model="searchQuery"
                  placeholder="Search..."
              />
                </label>
            </div>
          </form>
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-4">Rentals </h4>
            <form @submit.prevent="downloadRentalData">
              <div class="row mb-2" v-for="(d,i) in search_data" :key="i">
                <div class="col-md-3">
                  <select class="form-control" v-model="d.column" required @change="selectOptions(i)">
                    <option value="car_id">Car</option>
                    <option value="status">Status</option>
                    <option value="arrival_control_status">Arrival control status</option>
                    <option value="departure_controls_status">Departure controls status</option>
                    <option value="initial_fee_amount">Initial Fee Amount</option>
                    <option value="risk_of_damage_recurring_fee">Risk of damage recurring fee</option>
                    <option value="initial_fee_amount">Initial fee amount</option>
                    <option value="created_at">Created_at</option>
                    <option value="updated_at">Updated_at</option>
                  </select>
                </div>
                <div class="col-md-2"
                     v-if="d.column === 'arrival_control_status' || d.column === 'departure_controls_status' || d.column === 'status'">
                  <div class="form-group">
                    <select name="" class="form-control" v-model="d.input"
                    >
                      <option value="">All</option>
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2"
                     v-if="d.column === 'car_id'">
                  <div class="form-group">
                    <multiselect v-model="d.input" :options="cars.map(car => car.id)"
                                 :custom-label="opt => cars.find(x => x.id == opt).name"
                                 :multiple="false"></multiselect>

                  </div>
                </div>

                <div class="col-md-3"
                     v-if="d.column !== 'arrival_control_status' && d.column !== 'departure_controls_status' && d.column !== 'status' && d.column !== 'car_id'">
                  <select class="form-control" v-model="d.operator" required>
                    <option value="=">=</option>
                    <option value="<"><</option>
                    <option value=">"> ></option>
                    <option value="<="> <=</option>
                    <option value=">="> >=</option>
                    <option value="IS NULL">IS NULL</option>
                    <option value="IS NOT NULL">IS NOT NULL</option>
                    <option value="LIKE">contains</option>
                    <option value="NOT LIKE">Not contains</option>
                  </select>
                </div>
                <div class="col-md-3"
                     v-if="d.column !== 'arrival_control_status' && d.column !== 'departure_controls_status' && d.column !== 'status' && d.column !== 'car_id'">
                  <input type="text" class="form-control" v-model="d.input"/>
                </div>
                <div class="col-md-3">
                  <button class="btn mr-2 btn-light" v-if="search_data.length > 1" @click="removeSearch(i)"><i
                      class="fa fa-minus"></i></button>
                  <button class="btn btn-light" v-if="i === search_data.length-1" @click="addSearch()"><i
                      class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6 text-left">
                  <b-button size="sm" variant="info" @click="downloadRentalData"><i class="fa fa-download"/> Download
                    Data
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
          class="col-md-6 col-xl-3"
          v-for="(item, index) in filteredCars"
          :key="index"
      >
        <div class="card product-box">
          <div class="product-img">
            <!--              <template v-if="item['previous_assigned_rentals_with_pending_arrival_control'].length > 1">-->
            <!--                <div class="alert alert-danger m-1">{{item['previous_assigned_rentals_with_pending_arrival_control'].length}} pending arrival control</div>-->
            <!--              </template>-->
            <div class="p-3">
              <img :src="`${item.mainImage}`" alt="car-image" style="height: 150px; width: 200px; object-fit: contain"
                   class="img-fluid"/>
            </div>
            <div class="product-action">
              <div class="d-flex">
                <router-link :to="'/rentals/car-detail/' + item.id"
                             class="btn btn-white btn-block action-btn m-2"
                >
                  <i class="ri-edit-2-fill align-middle"></i> Details
                </router-link>
                <a v-if="item.status === 'available'"
                   @click="onCarRemoveTapped(item.id)"
                   href="javascript: void(0);"
                   class="btn btn-white btn-block action-btn m-2"
                ><i class="ri-delete-bin-fill align-middle"></i> Delete </a
                >
              </div>
            </div>
          </div>

          <div class="product-info border-top p-3">
            <div>

              <h5 class="font-16 mt-0 mb-1">
                <router-link
                    :to="'/rentals/car-detail/' + item.id"
                    class="text-dark"
                >
                  {{ item.name }} {{ item.specifications.length > 1 ? item.specifications[1].value : '' }}
                </router-link>
              </h5>
              <p class="text-muted mt-4">
                <span v-if="item.status === 'available' " class="badge badge-success p-1">{{ item.status }} </span>
                <span v-if="item.status === 'in-progress' "
                      class="badge badge-warning p-1 text-black-50">{{ 'Processing' }} </span>
                <span v-if="item.status === 'in-use' " class="badge badge-danger p-1">{{ item.status }} </span>
              </p>
              <h5 class="m-0" v-if="item['current_assigned_to']">
                <span
                    class="text-success"> assigned to: {{ item['current_assigned_to']['name'] }} {{ item['current_assigned_to']['other_names'] }} </span>
              </h5>
              <div class="mt-2" v-if="item['current_assigned_to']">
                <div class="row">
                  <div class="col-md-12">
                    <span class="badge badge-outline-dark badge-pill mr-2"
                          v-if="item['driver']"> dossier: {{ item['driver']['dossier'] }} </span>
                    <span class="badge badge-outline-info badge-pill"
                          v-if="item['prospectiveDriver']">{{ item['prospectiveDriver']['service_type'] | serviceType }} </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- end product info-->
        </div>
      </div>
    </div>
    <!-- end row -->

    <!--      <div class="row">-->
    <!--        <div class="col-12">-->
    <!--          <ul class="pagination pagination-rounded justify-content-end mb-3">-->
    <!--            <li class="page-item">-->
    <!--              <a-->
    <!--                  class="page-link"-->
    <!--                  href="javascript: void(0);"-->
    <!--                  aria-label="Previous"-->
    <!--              >-->
    <!--                <span aria-hidden="true">«</span>-->
    <!--                <span class="sr-only">Previous</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--            <li class="page-item active">-->
    <!--              <a class="page-link" href="javascript: void(0);">1</a>-->
    <!--            </li>-->
    <!--            <li class="page-item">-->
    <!--              <a class="page-link" href="javascript: void(0);">2</a>-->
    <!--            </li>-->
    <!--            <li class="page-item">-->
    <!--              <a class="page-link" href="javascript: void(0);">3</a>-->
    <!--            </li>-->
    <!--            <li class="page-item">-->
    <!--              <a class="page-link" href="javascript: void(0);">4</a>-->
    <!--            </li>-->
    <!--            <li class="page-item">-->
    <!--              <a class="page-link" href="javascript: void(0);">5</a>-->
    <!--            </li>-->
    <!--            <li class="page-item">-->
    <!--              <a class="page-link" href="javascript: void(0);" aria-label="Next">-->
    <!--                <span aria-hidden="true">»</span>-->
    <!--                <span class="sr-only">Next</span>-->
    <!--              </a>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--        </div>-->
    <!--        &lt;!&ndash; end col&ndash;&gt;-->
    <!--      </div>-->
    <!-- end row-->
  </div>

</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import {confirm} from "../../../../utils/functions";
import Multiselect from "vue-multiselect";
import {rentalsService} from "@/apis/rentals.service";

/**
 * cars-grid component
 */
export default {
  name: 'rentals',
  data() {
    return {
      search_data: [
        {
          operator: 'LIKE',
          column: 'status',
          input: ''
        }
      ],
      cars: [],
      selectedStatus: 'all',
      searchQuery: '',
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  computed: {
    filteredCars() {
      return this.cars.filter((car) => {
        // Filter by status
        if (this.selectedStatus !== 'all' && car.status !== this.selectedStatus) {
          return false;
        }

        // Search by name, current assigned to, etc.
        const searchTerms = [
          car.name,
          car.current_assigned_to ? car.current_assigned_to.name : '',
          car.specifications.length > 1 ? car.specifications[1].value : '',
          car.driver ? car.driver.dossier : '',
          // Add other fields you want to search here
        ];

        return searchTerms.some((term) =>
            term.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  methods: {
    onCarRemoveTapped(carId, status) {
      confirm('Remove this car from the system', () => {
        this.$store.dispatch('removeCar', carId)
      })
    },
    removeSearch(i) {
      this.search_data.splice(i, 1)
    },
    addSearch() {
      this.search_data.push({
        operator: 'LIKE',
        column: '',
        input: ''
      })
    },
    selectOptions(i) {
      this.search_data[i].input = '';
      if (this.search_data[i].column === 'current_status') {
        this.search_data[i].operator = '=';
      } else if (this.search_data[i].column === 'approved_by' || this.search_data[i].column === 'accepted_by' || this.search_data[i].column === 'declined_by') {
        this.search_data[i].operator = 'IN';
        this.search_data[i].input = [];
      }
    },
    getDownloadUrl() {
      const path = process.env.VUE_APP_API_BASE_URL + '/get-rental-data';
      location.replace(path);
    },
    downloadRentalData() {
      this.$store.dispatch('showLoader')
      rentalsService.downloadRentalData({search: this.search_data}).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.getDownloadUrl()
        this.$store.dispatch('success', {message: response.message, showSwal: true});
      });
    },
    getCars() {
      this.$store.dispatch('showLoader')
      rentalsService.fetchCars().then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.cars = response.extra
      });
    },
  },
  created() {
    this.getCars();
    this.$store.dispatch('setPageTitle', 'Show Room');
  }
};
</script>

